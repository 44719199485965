import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import Pagination, { PageItem } from "../components/Pagination";

import PostCard from "../components/PostCard";
import mailbox2 from "../assets/image/jpeg/mailbox2.jpg";
import packingImage from "../assets/image/jpeg/packing.jpg";
import mailboxImage from "../assets/image/jpeg/mailbox.jpg";
import concernsImage from "../assets/image/jpeg/concerns.jpg";
import chinaImage from "../assets/image/jpeg/china.jpg";
import faqImage from "../assets/image/jpeg/faq.jpg";
import parcelForwardingImage from "../assets/image/jpeg/parcel-forwarding.jpg";

import styles from '../assets/styles/blog.module.css';


const BlogRegular = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Title variant="hero">HotSnail Blog</Title>
                <Text>
                  Musings and thoughts from HotSnail
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={mailbox2}
                  preTitle="Jan 14, 2020"
                  title="Offering the lowest prices"
                  url="/blog/Lowest-prices"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Hotsnail offers the lowest prices for mail forwarding and mail scanning in the industry.
            </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={parcelForwardingImage}
                  preTitle="Nov 7, 2020"
                  title="Why your business should invest in parcel forwarding"
                  url="/blog/Invest-in-parcelforwarding"
                  readMore
                >
                  Parcel forwarding is a necessity for every modern-day business. This is due to the reality that most clients are always shifting their addresses, whether it be due to work or personal reasons....
            </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={chinaImage}
                  preTitle="Jan 14, 2020"
                  title="Shopping in Australia And sending to China"
                  url="/blog/Australia-to-china"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Want shipment from Australia to China? Say no more!
                  Now as simple as filling in the provided address!
            </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={packingImage}
                  preTitle="Nov 7, 2020"
                  title="Why parcel forwarding is essential for your small business"
                  url="/blog/ParcelForwarding-is-essential"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  When running a small business, you want to make sure that all your products reach your customers. You can now do this within your budget and convenience, thanks to parcel forwarding...
            </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={mailboxImage}
                  preTitle="Nov 7, 2020"
                  title="Virtual mailboxes – how this service benefits remote workers"
                  url="/blog/Virtual-mailboxes-services"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Plenty of businesses these days don’t require a dedicated office space to run. For many small- and medium-sized businesses, for example, working on the move or from home is not only possible but also practical...
            </PostCard>
              </Col>

              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={concernsImage}
                  preTitle="Nov 7, 2020"
                  title="Top 3 concerns about parcel forwarding answered"
                  url="/blog/Top-3-concerns"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  We’ve all been in this situation at one point: you hear of a unique product that catches your attention. Maybe it’s limited merchandise from a television show or a unique gadget that’s being sold in limited quantities...
              </PostCard>
              </Col>

              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={faqImage}
                  preTitle="Nov 7, 2020"
                  title="FAQs on mail forwarding and how it can ease your transition process"
                  url="/blog/FAQs-on-mail-forwarding"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  There are hundreds of things to stress over when you move houses. You’ll need to sort through your piles of clutter, pack everything into boxes, and physically transfer your personal belongings from one place to another...
            </PostCard>
              </Col>
              <Col lg="4" className="mb-5">
                <PostCard
                  className={styles.blogBox}
                  img={chinaImage}
                  preTitle="Jan 14, 2020"
                  title="Shopping in Australia And sending to China"
                  url="/blog/Australia-to-china"
                  imgClass={styles.blogGalleryImage}
                  readMore
                >
                  Want shipment from Australia to China? Say no more!
                  Now as simple as filling in the provided address!
            </PostCard>
              </Col>


            </Row>
            <Box className="d-flex justify-content-center" mt={4}>
              <Pagination>
                <PageItem href="/blog">1</PageItem>
                <PageItem active>2</PageItem>
              </Pagination>
            </Box>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default BlogRegular;
